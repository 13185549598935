/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


/**
 * Footer Google map
 * http://googlemaps.github.io/js-samples/styledmaps/wizard/index.html
 */
function initMap() {
  var mapCanvas = document.getElementById('google-map');
  if ( mapCanvas ) {
    var mapOptions = {
      center            : new google.maps.LatLng(45.5589939,-122.7350419),
      zoom              : 11,
      mapTypeId         : google.maps.MapTypeId.ROADMAP,
      zoomControl       : true,
      mapTypeControl    : false,
      scaleControl      : false,
      streetViewControl : false,
      rotateControl     : false
    };
    var map = new google.maps.Map(mapCanvas, mapOptions);
    map.set('styles',[
      {
        "featureType": "road",
        "stylers": [
          { "saturation": -100 },
          { "lightness": 45 }
        ]
      },{
        "featureType": "water",
        "stylers": [
          { "color": "#46bded" }
        ]
      },{
        "featureType": "landscape",
        "stylers": [
          { "color": "#f3f3f3" }
        ]
      },{
        "featureType": "landscape.natural",
        "stylers": [
          { "visibility": "simplified" }
        ]
      },{
        "featureType": "poi",
        "stylers": [
          { "visibility": "off" }
        ]
      },{
      },{
        "featureType": "transit",
        "stylers": [
          { "visibility": "off" }
        ]
      }
    ]);

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(45.5589504, -122.7318475),
      map: map
    });
  }
}
google.maps.event.addDomListener(window, 'load', initMap);





(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.hero-slides').flickity({
          imagesLoaded    : true,
          wrapAround      : true,
          pageDots        : ($('.hero-slides .slide').length > 1),
          prevNextButtons : false,
          autoPlay        : 6000
        });

        $('.photo-gallery-slides').flickity({
          imagesLoaded    : true,
          wrapAround      : false,
          pageDots        : false,
          prevNextButtons : false,
          autoPlay        : false
        });

        $('.photo-gallery-nav').flickity({
          asNavFor        : '.photo-gallery-slides',
          imagesLoaded    : true,
          contain         : true,
          prevNextButtons : false,
          pageDots        : false
        });

        // Fleet links
        $('.fleet-table').magnificPopup({
          type: 'image',
          delegate: 'a',
          gallery: {
            enabled: true
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // add wrapper to Flickity hero gallery
        if ( $('.hero-slides .flickity-page-dots').length > 0 ) {
          $('.hero-slides .flickity-page-dots').wrap('<div class="container"></div>');
        }

        // trigger resize for flickity galleries to fix some occasional load issues
        $('.hero-slides').flickity('resize');
        $('.photo-gallery-slides').flickity('resize');
        $('.photo-gallery-nav').flickity('resize');

        // Zurb's responsive tables
        function splitTable(original) {
          original.wrap("<div class='table-wrapper' />");
          var copy = original.clone();
          copy.find("td:not(:first-child), th:not(:first-child)").css("display", "none");
          copy.removeClass("responsive");
          original.closest(".table-wrapper").append(copy);
          copy.wrap("<div class='pinned' />");
          original.wrap("<div class='scrollable' />");
        }

        function unsplitTable(original) {
          original.closest(".table-wrapper").find(".pinned").remove();
          original.unwrap();
          original.unwrap();
        }

        var switched = false;
        var updateTables = function() {
          if (($(window).width() < 767) && !switched) {
            switched = true;
            $("table.responsive").each(function(i, element) { splitTable($(element)); });
            return true;
          } else if (switched && ($(window).width() > 767)) {
            switched = false;
            $("table.responsive").each(function(i, element) { unsplitTable($(element)); });
          }
        };

        $(window).load(updateTables);
        $(window).bind("resize", updateTables);
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
